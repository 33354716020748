import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getProfileTiersSagaAction,
  updateProfileTiersSagaAction,

} from './ProfileTierAction';
import {
  getProfileTiers,
  updateProfileTiers,
} from './ProfileTierWorker';

function* watchGetProfileTiers() {
  yield takeLatest(getProfileTiersSagaAction, getProfileTiers);
}

function* watchUpdateProfileTiers() {
  yield takeLatest(updateProfileTiersSagaAction, updateProfileTiers);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetProfileTiers),
    fork(watchUpdateProfileTiers),
  ]);
}
