import {
  all, fork, put, takeEvery,
} from 'redux-saga/effects';

import {
  changeLocaleSagaAction, changeLocaleReduxAction,
} from './SettingsAction';

function* changeLocale({ payload }) {
  localStorage.setItem('currentLanguage', payload);

  yield put(changeLocaleReduxAction(payload));
}

function* watchSetContainerClassnames() {
  yield takeEvery(changeLocaleSagaAction, changeLocale);
}

export default function* rootSaga() {
  yield all([
    fork(watchSetContainerClassnames),
  ]);
}
