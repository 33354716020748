/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const loginSagaAction = createAction('APZ_AUTH_LOGIN_SAGA');
export const loginSucceededReduxAction = createAction('APZ_AUTH_LOGIN_SUCCEEDED_REDUX');
export const loginFailedReduxAction = createAction('APZ_AUTH_LOGIN_FAILED_REDUX');

export const logoutSagaAction = createAction('APZ_AUTH_LOGOUT_SAGA');
export const logoutSucceededReduxAction = createAction('APZ_AUTH_LOGOUT_SUCCEEDED_REDUX');

export const updateMeSagaAction = createAction('APZ_AUTH_UPDATE_ME_SAGA');
export const updateMeSucceededReduxAction = createAction('APZ_AUTH_UPDATE_ME_SUCCEEDED_REDUX');
export const updateMeFailedReduxAction = createAction('APZ_AUTH_UPDATE_ME_FAILED_REDUX');

export const clearAuthErrorsOnLoadSucceededAction = createAction('APZ_AUTH_CLEAR_ERRORS_ON_LOAD');
