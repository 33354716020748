import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import _ from 'lodash';

import 'dropzone/dist/min/dropzone.min.css';

import config from '@app/config/app';

const ReactDOMServer = require('react-dom/server');

const dropzoneComponentConfig = {
  postUrl: `${config.apiUrl}/upload/posts-pictures`,
};

const dropzoneConfig = {
  paramName: 'image',
  thumbnailHeight: 160,
  maxFilesize: 2,
  maxFiles: 1,
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <div className="dz-preview dz-file-preview mb-3">
      <div className="d-flex flex-row ">
        <div className="p-0 w-30 position-relative">
          <div className="dz-error-mark">
            <span>
              <i />{' '}
            </span>
          </div>
          <div className="dz-success-mark">
            <span>
              <i />
            </span>
          </div>
          <div className="preview-container">
            {/*  eslint-disable-next-line jsx-a11y/alt-text */}
            <img data-dz-thumbnail className="img-thumbnail border-0" />
            <i className="simple-icon-doc preview-icon" />
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
          <div>
            {' '}
            <span data-dz-name />{' '}
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
      </div>
      <a href="#/" className="remove" data-dz-remove>
        {' '}
        <i className="glyph-icon simple-icon-trash" />{' '}
      </a>
    </div>,
  ),
};

class DropzoneExample extends Component {
  eventHandlers() {
    const self = this;

    return {
      init: (dropzone) => {
        DropzoneExample.autoDiscover = false;
        const { images } = this.props;
        images.forEach((i) => {
          const file = {};
          dropzone.emit('addedfile', file);
          dropzone.emit('thumbnail', file, i.small);
          dropzone.emit('complete', file);
        });
      },
      success: self.onSuccess,
    };
  }

  onSuccess = (file, response) => {
    const { onSuccess } = this.props;
    onSuccess(_.reduce(response, _.extend));
  }

  render() {
    return (
      <DropzoneComponent
        config={dropzoneComponentConfig}
        djsConfig={dropzoneConfig}
        eventHandlers={this.eventHandlers()}
      />
    );
  }
}

DropzoneExample.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  images: PropTypes.array,
};

DropzoneExample.defaultProps = {
  images: [],
};

export default DropzoneExample;
