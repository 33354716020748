/* eslint-disable import/prefer-default-export */
import { put, call } from 'redux-saga/effects';

import { getRequest, postRequest, putRequest } from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';
import LocaleService from '@services/LocaleService';

import {
  loginSucceededReduxAction, loginFailedReduxAction,
  logoutSucceededReduxAction,
  updateMeSucceededReduxAction, updateMeFailedReduxAction,
} from './AuthAction';

const translations = LocaleService.getTranslations('general.error');
/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* login({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, 'admin/login', payload.data);
    const response = yield request.$promise;
    yield put(loginSucceededReduxAction(response.data));

    // if success reload the page
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { response } = error;
    if (response && response.status === 422) {
      yield put(loginFailedReduxAction(response.data.errors));

      if (onError) {
        onError();
      }
      return;
    } if (response && response.status === 401) {
      yield put(loginFailedReduxAction({ email: [ translations.invalid_credentials.message ] }));

      if (onError) {
        onError();
      }
      return;
    }
    yield put(loginFailedReduxAction({}));
    handleError(error);

    if (onError) {
      onError();
    }
  }
}

export function* logout({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(getRequest, 'admin/logout');
    yield request.$promise;
    yield put(logoutSucceededReduxAction());

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError();
    }
  }
}

export function* updateMe({ payload }) {
  const {
    onSuccess, onError, params,
  } = payload;
  try {
    const request = yield call(putRequest, 'admin/me', payload.data);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
    yield put(updateMeSucceededReduxAction(params));
  } catch (error) {
    const { response } = error;
    if (onError) {
      onError();
    }
    if (response && response.status === 422) {
      yield put(updateMeFailedReduxAction(response.data.errors));
      return;
    }
    handleError(error);
  }
}
