/* eslint-disable import/prefer-default-export */
import { put, call } from 'redux-saga/effects';

import {
  getRequest, postRequest,
} from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import {
  getLocalOffersSucceededReduxAction,
  removeLocalOffersSucceededReduxAction,
  restoreLocalOffersSucceededReduxAction,
  deleteLocalOffersSucceededReduxAction,
} from './AdvertisementAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getLocalOffers({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    const request = yield call(getRequest, 'admin/advertisement/local-offers', { queryParams: data });
    const response = yield request.$promise;
    yield put(getLocalOffersSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* removeLocalOffers({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, `admin/advertisement/remove-offers/${data.id}`);
    const response = yield request.$promise;
    yield put(removeLocalOffersSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* restoreLocalOffers({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, `admin/advertisement/restore-offers/${data.id}`);
    const response = yield request.$promise;
    yield put(restoreLocalOffersSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* deleteLocalOffers({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, `admin/advertisement/delete-offers/${data.id}`);
    const response = yield request.$promise;
    yield put(deleteLocalOffersSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}
