/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';

import {
  getRequest, postRequest, deleteRequest,
} from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import {
  getEngagementRewardVariableSuccessReduxAction,
  savePublishEngagementRewardSuccessAction,

  getPublishEngagementRewardSuccessAction,
} from './EngagementRewardAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getEngagementReward({ payload }) {
  try {
    const request = yield call(getRequest, 'admin/setting/variable/engagement-rewards', { queryParams: payload.data });
    const response = yield request.$promise;

    if (payload.onSuccess) {
      payload.onSuccess(response.data);
    }
  } catch (error) {
    if (payload.onError) {
      payload.onError(error);
    }
    handleError(error);
  }
}

export function* saveEngagementReward({ payload }) {
  try {
    const request = yield call(postRequest, 'admin/setting/variable/engagement-rewards', payload.data);
    yield request.$promise;
    payload.onSuccess();
  } catch (error) {
    payload.onError(error);
  }
}

export function* publishEngagementReward({ payload }) {
  try {
    const request = yield call(postRequest, `admin/setting/variable/engagement-rewards/${payload.id}/publish`);
    yield request.$promise;
    payload.onSuccess();
  } catch (error) {
    payload.onError(error);
    handleError(error);
  }
}

export function* getEngagementRewardVariable({ payload = {} }) {
  try {
    const request = yield call(getRequest, 'admin/setting/variable/engagement-rewards/variables', { queryParams: payload.data });
    const response = yield request.$promise;

    yield put(getEngagementRewardVariableSuccessReduxAction(response.data));
  } catch (error) {
    handleError(error);
  }
}

export function* getPublishEngagementReward({ payload = {} }) {
  try {
    const request = yield call(getRequest, 'admin/setting/variable/engagement-rewards/published', { queryParams: payload.data });
    const response = yield request.$promise;

    yield put(getPublishEngagementRewardSuccessAction(response.data));

    if (payload.onSuccess) {
      payload.onSuccess(response.data);
    }
  } catch (error) {
    if (payload.onError) {
      payload.onError(error);
    }
    handleError(error);
  }
}

export function* getActivePublishEngagementReward({ payload = {} }) {
  try {
    const request = yield call(getRequest, 'admin/setting/variable/engagement-rewards/published', { queryParams: payload.data });
    const response = yield request.$promise;

    payload.onSuccess(response.data);
  } catch (error) {
    handleError(error);
  }
}

export function* savePublishEngagementReward({ payload }) {
  try {
    const request = yield call(postRequest, 'admin/setting/variable/engagement-rewards/published', payload.data);
    const response = yield request.$promise;

    yield put(savePublishEngagementRewardSuccessAction(response.data));
    payload.onSuccess(response.data);
  } catch (error) {
    payload.onError(error);
  }
}

export function* removePublishEngagementReward({ payload = {} }) {
  try {
    const request = yield call(deleteRequest, `admin/setting/variable/engagement-rewards/published/${payload.id}`);
    const response = yield request.$promise;

    payload.onSuccess(response.data);
  } catch (error) {
    payload.onError(error);
    handleError(error);
  }
}
