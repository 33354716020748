export default {
  /*
    Menu Types:
    "menu-default", "menu-sub-hidden", "menu-hidden"
  */
  defaultMenuType: 'menu-default',

  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,

  defaultLocale: 'en',
  localeOptions: [
    { id: 'en', name: 'English - LTR', direction: 'ltr' },
    { id: 'es', name: 'Español', direction: 'ltr' },
    { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  ],

  searchPath: '/app/pages/search',
  servicePath: 'https://api.coloredstrategies.com',

  /*
    Color Options:
    "light.purple", "light.blue", "light.green", "light.orange", "light.red",
    "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
  */
  isMultiColorActive: true,
  defaultColor: 'light.purple',
  defaultDirection: 'ltr',
  isDarkSwitchActive: false,
  themeColorStorageKey: '__theme_color',
  themeRadiusStorageKey: '__theme_radius',
  isDemo: false,
};
