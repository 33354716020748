import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getSystemSettingsSagaAction,
  updateSystemSettingsSagaAction,

} from './SystemSettingsAction';
import {
  getSystemSettings,
  updateSystemSettings,
} from './SystemSettingsWorker';

function* watchGetSystemSettings() {
  yield takeLatest(getSystemSettingsSagaAction, getSystemSettings);
}

function* watchUpdateSystemSettings() {
  yield takeLatest(updateSystemSettingsSagaAction, updateSystemSettings);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSystemSettings),
    fork(watchUpdateSystemSettings),
  ]);
}
