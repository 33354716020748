import { handleActions } from 'redux-actions';

import constantsConfig from '@config/constants';

import {
  changeLocaleReduxAction,
} from './SettingsAction';

const { defaultLocale, localeOptions } = constantsConfig;

// reducers
const defaultState = {
  locale: (localStorage.getItem('currentLanguage') && localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0)
    ? localStorage.getItem('currentLanguage')
    : defaultLocale,
};

const reducer = handleActions({
  // getHomeAction = Variable| state, action(payload)
  [changeLocaleReduxAction](state, { payload }) {
    return { ...state, locale: payload };
  },
}, defaultState);

export default reducer;
