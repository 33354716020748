import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getDashboardInfoSagaAction,
} from './DashboardAction';
import {
  getDashboardInfo,
} from './DashboardWorker';

function* watchGetDashboardInfo() {
  yield takeLatest(getDashboardInfoSagaAction, getDashboardInfo);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetDashboardInfo),
  ]);
}
