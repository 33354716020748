/* eslint-disable import/prefer-default-export */

import LocaleService from '@services/LocaleService';

import { NotificationManager } from '@shared/utils/Notification';

const translations = LocaleService.getTranslations('general.error');

const errorResponse = {
  429: translations.throttle,
  500: translations.internal_server,
  401: translations.invalid_credentials,
};

const parseErrorResponse = (response) => {
  // default message
  let message = translations.invalid_request;
  // means no internet or couldn't establish connection
  if (!response) {
    message = translations.establish_connection;
  } else if (response.status === 422) {
    // validation error
    // temp: get the first error
    const { errors } = response.data;
    // eslint-disable-next-line prefer-destructuring
    message = {
      title: translations.validation_error.title,
      message: Object.values(errors)[0][0],
    };
  } else if (response.status === 400) {
    // eslint-disable-next-line prefer-destructuring
    message = {
      title: message.title,
      message: response.data.message,
    };
  } else if (errorResponse[response.status]) {
    // if status code is defined in error response object
    message = errorResponse[response.status];
  }

  return message;
};

export const handleError = (err) => {
  const { response } = err;

  const { title, message } = parseErrorResponse(response);
  // implement display of error here
  NotificationManager.error(message, title);
};

// eslint-disable-next-line no-console
export const logError = (error) => console.log('error in request', error);
