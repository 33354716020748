import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import DataTablePagination from '../DataTablePagination';

const DataTable = ({ dataTableColumns, ...rest }) => (
  <ReactTable
    columns={dataTableColumns}
    defaultPageSize={5}
    showPageJump
    showPageSizeOptions
    PaginationComponent={DataTablePagination}
    className="react-table-fixed-height"
    manual
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

DataTable.defaultProps = {
  data: [],
};

DataTable.propTypes = {
  data: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
};

export default DataTable;
