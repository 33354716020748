/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getEngagementRewardSagaAction = createAction('APZ_ENGAGEMENT_REWARD_GET_ALL');
export const saveEngagementRewardSagaAction = createAction('APZ_ENGAGEMENT_REWARD_SAVE_ALL');
export const publishEngagementRewardSagaAction = createAction('APZ_ENGAGEMENT_REWARD_PUBLISH');

export const getPublishEngagementRewardSagaAction = createAction('APZ_ENGAGEMENT_REWARD_GET_PUBLISHED');
export const getActivePublishEngagementRewardSagaAction = createAction('APZ_ENGAGEMENT_REWARD_GET_ACTIVE_PUBLISHED');
export const getPublishEngagementRewardSuccessAction = createAction('APZ_ENGAGEMENT_REWARD_GET_PUBLISHED_SUCCESS_REDUX');

export const savePublishEngagementRewardSagaAction = createAction('APZ_ENGAGEMENT_REWARD_SAVE_PUBLISHED');
export const savePublishEngagementRewardSuccessAction = createAction('APZ_ENGAGEMENT_REWARD_SAVE_PUBLISHED_SUCCESS_REDUX');

export const removePublishEngagementRewardSagaAction = createAction('APZ_ENGAGEMENT_REWARD_REMOVE_PUBLISHED');

export const getEngagementRewardVariableSagaAction = createAction('APZ_ENGAGEMENT_REWARD_GET_VARIABLE');
export const getEngagementRewardVariableSuccessReduxAction = createAction('APZ_ENGAGEMENT_REWARD_GET_VARIABLE_SUCCESS_REDUX');
