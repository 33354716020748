import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getLocalOffersSagaAction,
  removeLocalOffersSagaAction,
  restoreLocalOffersSagaAction,
  deleteLocalOffersSagaAction,
} from './AdvertisementAction';
import {
  getLocalOffers,
  removeLocalOffers,
  restoreLocalOffers,
  deleteLocalOffers,
} from './AdvertisementWorker';

function* watchGetLocalOffers() {
  yield takeLatest(getLocalOffersSagaAction, getLocalOffers);
}

function* watchRemoveLocalOffers() {
  yield takeLatest(removeLocalOffersSagaAction, removeLocalOffers);
}

function* watchRestoreLocalOffers() {
  yield takeLatest(restoreLocalOffersSagaAction, restoreLocalOffers);
}

function* watchDeleteLocalOffers() {
  yield takeLatest(deleteLocalOffersSagaAction, deleteLocalOffers);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetLocalOffers),
    fork(watchRemoveLocalOffers),
    fork(watchRestoreLocalOffers),
    fork(watchDeleteLocalOffers),
  ]);
}
