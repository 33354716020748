import { handleActions } from 'redux-actions';

import constantsConfig from '@config/constants';
import {
  setContainerClassnamesReduxAction,
  addContainerClassnameReduxAction,
  clickMobileMenuReduxAction,
  changeDefaultClassesReduxAction,
  changeHasSubItemStatusReduxAction,

  // Example of async actions
  // incrementSucceededReduxAction,
  // incrementFailedReduxAction,
} from './MenuAction';

const { defaultMenuType, subHiddenBreakpoint, menuHiddenBreakpoint } = constantsConfig;

// reducers
const defaultState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: defaultMenuType === 'menu-default', // if you use menu-sub-hidden as default menu type, set value of this variable to false
};

const reducer = handleActions({
  [setContainerClassnamesReduxAction](state, { payload }) {
    const { containerClassnames, menuClickCount } = payload;
    return { ...state, containerClassnames, menuClickCount };
  },
  [addContainerClassnameReduxAction](state, { payload }) {
    return { ...state, containerClassnames: payload };
  },
  [clickMobileMenuReduxAction](state, { payload }) {
    const { containerClassnames, menuClickCount } = payload;
    return { ...state, containerClassnames, menuClickCount };
  },
  [changeDefaultClassesReduxAction](state, { payload }) {
    return { ...state, containerClassnames: payload };
  },
  [changeHasSubItemStatusReduxAction](state, { payload }) {
    return { ...state, selectedMenuHasSubItems: payload };
  },
  // Example of handle actions
  // [incrementSucceededReduxAction](state, { payload }) {
  //   const { count } = state;
  //   return { ...state, count: count + payload.counter };
  // },
  // [incrementFailedReduxAction](state) {
  //   return { ...state };
  // },
}, defaultState);

export default reducer;
