import {
  all, fork, takeEvery,
} from 'redux-saga/effects';

import {
  getCommentReportsSagaAction,
  getPostReportsSagaAction,
  getUserReportsSagaAction,
  removeReportedItemSagaAction,
  restoreReportedItemSagaAction,
  ignoreReportedItemSagaAction,
  getItemReportsSagaAction,
} from './ReportAction';
import {
  getCommentReports,
  getPostReports,
  getUserReports,
  removeReportedItem,
  restoreReportedItem,
  ignoreReportedItem,
  getItemReports,
} from './ReportWorker';

function* watchGetCommentReports() {
  yield takeEvery(getCommentReportsSagaAction, getCommentReports);
}

function* watchGetPostReports() {
  yield takeEvery(getPostReportsSagaAction, getPostReports);
}

function* watchGetUserReports() {
  yield takeEvery(getUserReportsSagaAction, getUserReports);
}

function* watchRemoveReportedItem() {
  yield takeEvery(removeReportedItemSagaAction, removeReportedItem);
}

function* watchRestoreReportedItem() {
  yield takeEvery(restoreReportedItemSagaAction, restoreReportedItem);
}

function* watchIgnoreReportedItem() {
  yield takeEvery(ignoreReportedItemSagaAction, ignoreReportedItem);
}

function* watchGetItemReports() {
  yield takeEvery(getItemReportsSagaAction, getItemReports);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCommentReports),
    fork(watchGetPostReports),
    fork(watchGetUserReports),
    fork(watchRemoveReportedItem),
    fork(watchRestoreReportedItem),
    fork(watchIgnoreReportedItem),
    fork(watchGetItemReports),
  ]);
}
