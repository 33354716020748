import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Pagination, PaginationItem, PaginationLink,
  UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem,
} from 'reactstrap';

class DataTablePagination extends Component {
  constructor(props) {
    super(props);

    const { page, defaultPageSize: pageSize } = props;

    this.state = {
      page,
      pageSize,
    };
  }

  static getDerivedStateFromProps(props) {
    const { page } = props;
    return { page };
  }

  getSafePage = (page) => {
    const { page: currentPage, pages } = this.props;
    let updatedPage = page;
    if (Number.isNaN(updatedPage)) {
      updatedPage = currentPage;
    }

    return Math.min(Math.max(page, 0), pages - 1);
  }

  changePageSize = (pageSize) => {
    const { onPageSizeChange } = this.props;
    onPageSizeChange(pageSize);
    this.setState({ pageSize });
  }

  changePage = (page) => {
    const { page: currentPage, onPageChange } = this.props;
    const updatedPage = this.getSafePage(page);
    this.setState({ page: updatedPage });
    if (currentPage !== updatedPage) {
      onPageChange(updatedPage);
    }
  }

  applyPage = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { page } = this.state;
    const { page: currentPage } = this.props;

    this.changePage(page === '' ? currentPage : page);
  }

  pageClick = (pageIndex) => {
    this.changePage(pageIndex);
  }

  renderPages = () => {
    const { pages } = this.props;
    const { page } = this.state;
    const pageCount = pages;
    let length = 10;
    length = length > pageCount ? pageCount : length;

    let start = page - Math.floor(length / 2);
    start = Math.max(start, 1);
    start = Math.min(start, 1 + pageCount - length);

    const range = Array.from({ length }, (el, i) => start + i);

    return range.map((p) => (
      <PaginationItem key={p} active={p - 1 === page}>
        <PaginationLink
          onClick={() => this.pageClick(p - 1)}
        >{p}
        </PaginationLink>
      </PaginationItem>
    ));
  }

  renderPageJump = () => {
    const { pages } = this.props;
    const pageNumbers = [];
    for (let i = 0; i < pages; i++) {
      pageNumbers.push(
        <DropdownItem
          key={i}
          onClick={() => this.changePage(i)}
        >
          {i + 1}
        </DropdownItem>,
      );
    }
    return pageNumbers;
  }

  render() {
    const {
      page: currentPage,
      pages,
      canPrevious,
      canNext,
      pageSizeOptions,
      showPageSizeOptions,
      showPageJump,
    } = this.props;
    const { page, pageSize } = this.state;

    return (
      <>
        <div className="text-center">
          {
            showPageJump
            && (
              <div className="float-left pt-2"><span>Page </span>
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle caret color="outline-primary" size="xs">
                    {page + 1}
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      maxHeight: '400px',
                      overflowY: 'scroll',
                    }}
                  >
                    {this.renderPageJump()}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <span> of </span>{pages}
              </div>
            )
          }

          <Pagination className="d-inline-block" size="sm" listClassName="justify-content-center" aria-label="Page navigation example">
            <PaginationItem className={`${!canPrevious && 'disabled'}`}>
              <PaginationLink
                className="prev"
                onClick={() => {
                  if (!canPrevious) return;
                  this.changePage(currentPage - 1);
                }}
                disabled={!canPrevious}
              >
                <i className="simple-icon-arrow-left" />
              </PaginationLink>
            </PaginationItem>

            {this.renderPages()}
            <PaginationItem className={`${!canNext && 'disabled'}`}>
              <PaginationLink
                className="next"
                onClick={() => {
                  if (!canNext) return;
                  this.changePage(currentPage + 1);
                }}
                disabled={!canNext}
              >
                <i className="simple-icon-arrow-right" />
              </PaginationLink>
            </PaginationItem>
          </Pagination>
          {
            showPageSizeOptions
            && (
              <div className="float-right pt-2">
                <span className="text-muted text-small mr-1">Items </span>
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle caret color="outline-primary" size="xs">
                    {pageSize}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {pageSizeOptions.map((size, index) => (
                      <DropdownItem
                        key={index.toString()}
                        onClick={() => this.changePageSize(size)}
                      >
                        {size}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )
          }
        </div>
      </>
    );
  }
}

DataTablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  canNext: PropTypes.bool.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  showPageSizeOptions: PropTypes.bool.isRequired,
  showPageJump: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
};

export default DataTablePagination;
