import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';
import './styles.scss';

const NotificationItem = ({
  title, description, createdAt, readAt,
  descriptionClassnames,
}) => (
  <div className={`d-flex flex-row p-3 pb-3 border-bottom ${isEmpty(readAt) ? 'unread' : ''}`}>
    <div className="px-1">
      <p className="font-weight-bold mb-1">{title}</p>
      <p className={descriptionClassnames}>{description}</p>
      <p className="text-muted mb-0 text-small">{moment(createdAt).fromNow()}</p>
    </div>
  </div>
);

NotificationItem.defaultProps = {
  readAt: '',
  descriptionClassnames: 'mb-1 notification-description',
};

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  descriptionClassnames: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  readAt: PropTypes.string,
};

export default NotificationItem;
