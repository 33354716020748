import { handleActions } from 'redux-actions';

import {
  loginSucceededReduxAction, loginSagaAction, loginFailedReduxAction,
  logoutSucceededReduxAction,
  updateMeSagaAction, updateMeSucceededReduxAction, updateMeFailedReduxAction,
  clearAuthErrorsOnLoadSucceededAction,
} from './AuthAction';

// reducers
const defaultState = {
  loading: false,
  accessToken: null,
  user: {},
  errors: {},
};

const reducer = handleActions({
  [loginSagaAction](state) {
    return { ...state, loading: true, errors: {} };
  },
  [loginSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      accessToken: payload.access_token,
      user: payload.user,
      loading: false,
    };
  },
  [loginFailedReduxAction](state, { payload }) {
    return { ...state, loading: false, errors: payload };
  },
  [logoutSucceededReduxAction]() {
    return defaultState;
  },

  [updateMeSagaAction](state) {
    return { ...state, loading: true, errors: {} };
  },
  [updateMeSucceededReduxAction](state, { payload }) {
    const { user } = state;
    return {
      ...state,
      loading: false,
      errors: {},
      user: {
        ...user,
        ...payload,
      },
    };
  },
  [updateMeFailedReduxAction](state, { payload }) {
    return { ...state, loading: false, errors: payload };
  },

  [clearAuthErrorsOnLoadSucceededAction](state) {
    return { ...state, errors: {} };
  },
}, defaultState);

export default reducer;
