/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getGlobalProfilesSagaAction = createAction('APZ_GLOBAL_GET_PROFILES');
export const getGlobalProfilesSucceededReduxAction = createAction('APZ_GLOBAL_GET_PROFILES_SUCCEEDED_REDUX');
export const saveGlobalProfileTabReduxAction = createAction('APZ_GLOBAL_SAVE_GLOBAL_PROFILE_TAB_REDUX');

export const banGlobalProfileSagaAction = createAction('APZ_GLOBAL_BAN_PROFILE');
export const banGlobalProfileSucceededReduxAction = createAction('APZ_GLOBAL_BAN_PROFILE_SUCCEEDED_REDUX');

export const unbanGlobalProfileSagaAction = createAction('APZ_GLOBAL_UNBAN_PROFILE');
export const unbanGlobalProfileSucceededReduxAction = createAction('APZ_GLOBAL_UNBAN_PROFILE_SUCCEEDED_REDUX');

export const showGlobalProfileSagaAction = createAction('APZ_GLOBAL_SHOW_PROFILE');
export const showGlobalProfileSucceededReduxAction = createAction('APZ_GLOBAL_SHOW_PROFILE_SUCCEEDED_REDUX');

export const globalProfileChangeTypeSagaAction = createAction('APZ_GLOBAL_CHANGE_TYPE');
export const globalProfileChangeTypeSucceededReduxAction = createAction('APZ_GLOBAL_CHANGE_TYPE_SUCCEEDED_REDUX');
