import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getGlobalProfilesSagaAction,
  banGlobalProfileSagaAction,
  unbanGlobalProfileSagaAction,
  showGlobalProfileSagaAction,
  globalProfileChangeTypeSagaAction,
} from './GlobalProfileAction';
import {
  getGlobalProfiles,
  banGlobalProfile,
  unbanGlobalProfile,
  showGlobalProfile,
  globalProfileChangeType,
} from './GlobalProfileWorker';

function* watchGetGlobalProfiles() {
  yield takeLatest(getGlobalProfilesSagaAction, getGlobalProfiles);
}

function* watchBanGlobalProfile() {
  yield takeLatest(banGlobalProfileSagaAction, banGlobalProfile);
}

function* watchUnbanGlobalProfile() {
  yield takeLatest(unbanGlobalProfileSagaAction, unbanGlobalProfile);
}

function* watchShowGlobalProfile() {
  yield takeLatest(showGlobalProfileSagaAction, showGlobalProfile);
}

function* watchGlobalProfileChangeType() {
  yield takeLatest(globalProfileChangeTypeSagaAction, globalProfileChangeType);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetGlobalProfiles),
    fork(watchBanGlobalProfile),
    fork(watchUnbanGlobalProfile),
    fork(watchShowGlobalProfile),
    fork(watchGlobalProfileChangeType),
  ]);
}
