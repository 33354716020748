/* eslint-disable import/prefer-default-export */
import { put, call } from 'redux-saga/effects';

import {
  getRequest, postRequest, putRequest, deleteRequest,
} from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import {
  createPostFailedReduxAction,
  editPostFailedReduxAction,
} from './FeedAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getPosts({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/posts', { queryParams: data });
    const response = yield request.$promise;

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* createPost({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, 'admin/posts', payload.data);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { response } = error;
    if (onError) {
      onError();
    }
    if (response && response.status === 422) {
      yield put(createPostFailedReduxAction(response.data.errors));
      return;
    }
    handleError(error);
  }
}

export function* editPost({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(putRequest, `admin/posts/${payload.id}`, payload.data);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { response } = error;
    if (onError) {
      onError();
    }
    if (response && response.status === 422) {
      yield put(editPostFailedReduxAction(response.data.errors));
      return;
    }
    handleError(error);
  }
}

export function* deletePost({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(deleteRequest, `admin/posts/${payload.id}`);
    yield request.$promise;

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getGlobalProfiles({ payload }) {
  const { data, onSuccess, onError } = payload;

  try {
    const request = yield call(getRequest, 'admin/global-profiles', { queryParams: data });
    const response = yield request.$promise;

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}
