import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getPostsSagaAction,
  createPostSagaAction,
  getGlobalProfilesSagaAction,
  editPostSagaAction,
  deletePostSagaAction,
} from './FeedAction';
import {
  getPosts,
  createPost,
  getGlobalProfiles,
  editPost,
  deletePost,
} from './FeedWorker';

function* watchGetPosts() {
  yield takeLatest(getPostsSagaAction, getPosts);
}

function* watchCreatePost() {
  yield takeLatest(createPostSagaAction, createPost);
}

function* watchEditPost() {
  yield takeLatest(editPostSagaAction, editPost);
}

function* watchDeletePost() {
  yield takeLatest(deletePostSagaAction, deletePost);
}

function* watchGetGlobalProfiles() {
  yield takeLatest(getGlobalProfilesSagaAction, getGlobalProfiles);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPosts),
    fork(watchCreatePost),
    fork(watchEditPost),
    fork(watchDeletePost),
    fork(watchGetGlobalProfiles),
  ]);
}
