/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const Colxx = (props) => (
  <Col {...props} widths={[ 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl' ]} />
);
const Separator = ({ className }) => (
  <div className={`separator ${className}`} />
);

Separator.propTypes = {
  className: PropTypes.string.isRequired,
};

export { Colxx, Separator };
