/* eslint-disable import/prefer-default-export */
import appConfig from '@config/app';

const { google } = appConfig;
// Helper methods to standardize outputs

const computeViewbox = (lat, lng) => {
  const pi = Math.PI;
  const radiusKm = 25;

  // 1km in degree = 1 / 111.32km = 0.0089
  const coef = radiusKm * 0.0089;

  const leftLat = lat + coef;
  const leftLng = lng + coef / Math.cos(lng * pi);

  const rightLat = lat - coef;
  const rightLng = lng - coef / Math.cos(lng * pi);

  return `${leftLng},${leftLat},${rightLng},${rightLat}`;
};

export const getShortAddress = (location) => {
  if (location && location.name) {
    return location.name;
  }
  if (location && location.location) {
    const { location: loc } = location;
    return `${loc.address} ${loc.city}`.trim();
  }
  return '';
};

export const getDescription = (location, name) => {
  const defaultDescription = location.address || name;
  return location.address && location.city ? `${location.address}, ${location.city}` : defaultDescription;
};

export const getLocationDetails = (venue) => {
  if (!venue) return null;

  const { display_name: name } = venue;
  return {
    id: venue.place_id,
    location: {
      lat: parseFloat(venue.lat),
      lng: parseFloat(venue.lon),
      shortAddress: name,
      description: name,
    },
  };
};

export const getPlaceDetails = ({ predictions = [] }) => predictions.map(({
  place_id, description, structured_formatting: format = {},
}) => ({
  id: place_id,
  location: {
    shortAddress: format.main_text || description,
    description,
  },
}));

export const getPlaceIdDetails = ({ result = {} }) => {
  const { place_id, geometry } = result;

  return {
    id: place_id,
    location: geometry?.location,
  };
};

//  requests
export const getLocationRequest = (data) => ({
  url: 'maps/api/geocode/json',
  data: {
    queryParams: {
      lat: data.lat,
      lon: data.lng,
    },
  },
  transformer: getLocationDetails,
});

export const getSuggestedPlacesRequest = (data) => {
  const viewbox = computeViewbox(data.latitude, data.longitude);
  const params = {
    url: 'maps/api/place/autocomplete/json',
    data: {
      queryParams: {
        input: data.query,
        // location: data.latitude ? `${data.latitude},${data.longitude}` : null,
        key: google.apiKey,
      },
    },
    transformer: getPlaceDetails,
  };
  if (viewbox) {
    params.viewbox = viewbox;
  }

  return params;
};

export const getPlaceDetailRequest = (data) => {
  const params = {
    url: 'maps/api/place/details/json',
    data: {
      queryParams: {
        placeid: data.placeid,
        key: google.apiKey,
      },
    },
    transformer: getPlaceIdDetails,
  };

  return params;
};
