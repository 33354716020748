/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
// Component -> Saga -> Redux
// actionNameSagaAction - Saga (Component to Saga)
// actionNameReduxAction - Redux (Saga to Redux or Component to Redux)
export const setContainerClassnamesSagaAction = createAction('USHX_MENU_SET_CONTAINER_CLASSNAMES_SAGA');
export const setContainerClassnamesReduxAction = createAction('USHX_MENU_SET_CONTAINER_CLASSNAMES_REDUX');

export const addContainerClassnameSagaAction = createAction('USHX_MENU_ADD_CONTAINER_CLASSNAME_SAGA');
export const addContainerClassnameReduxAction = createAction('USHX_MENU_ADD_CONTAINER_CLASSNAME_REDUX');

export const clickMobileMenuSagaAction = createAction('USHX_MENU_CLICK_MOBILE_MENU_SAGA');
export const clickMobileMenuReduxAction = createAction('USHX_MENU_CLICK_MOBILE_MENU_REDUX');

export const changeDefaultClassesReduxAction = createAction('USHX_MENU_CHANGE_DEFAULT_CLASSES_REDUX');
export const changeHasSubItemStatusReduxAction = createAction('USHX_MENU_CHANGE_HAS_SUB_ITEM_STATUS_REDUX');

// Example of saga
// export const incrementSagaAction = createAction('USHX_MENU_INCREMENT_SAGA');
// eslint-disable-next-line max-len
// export const incrementSucceededReduxAction = createAction('USHX_MENU_INCREMENT_SUCCEEDED_REDUX');
// export const incrementFailedReduxAction = createAction('USHX_MENU_INCREMENT_FAILED_REDUX');
