import { handleActions } from 'redux-actions';

import {
  toggleRefreshReduxAction,
} from './LoadingAction';

// reducers
const defaultState = {
  isLoading: false,
  error: '',
};

const reducer = handleActions({
  [toggleRefreshReduxAction](state, { payload }) {
    const { isLoading } = state;
    return { ...state, isLoading: !isLoading, error: payload };
  },
}, defaultState);

export default reducer;
