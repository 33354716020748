import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getEngagementRewardSagaAction,
  saveEngagementRewardSagaAction,
  publishEngagementRewardSagaAction,

  getActivePublishEngagementRewardSagaAction,
  getPublishEngagementRewardSagaAction,
  savePublishEngagementRewardSagaAction,
  removePublishEngagementRewardSagaAction,

  getEngagementRewardVariableSagaAction,
} from './EngagementRewardAction';
import {
  getEngagementReward,
  saveEngagementReward,
  publishEngagementReward,

  getActivePublishEngagementReward,
  getPublishEngagementReward,
  savePublishEngagementReward,
  removePublishEngagementReward,

  getEngagementRewardVariable,
} from './EngagementRewardWorker';

function* watchGetEngagementReward() {
  yield takeLatest(getEngagementRewardSagaAction, getEngagementReward);
}

function* watchGetEngagementRewardVariable() {
  yield takeLatest(getEngagementRewardVariableSagaAction, getEngagementRewardVariable);
}

function* watchSaveEngagementReward() {
  yield takeLatest(saveEngagementRewardSagaAction, saveEngagementReward);
}

function* watchPublishEngagementReward() {
  yield takeLatest(publishEngagementRewardSagaAction, publishEngagementReward);
}

function* watchGetPublishEngagementReward() {
  yield takeLatest(getPublishEngagementRewardSagaAction, getPublishEngagementReward);
}

function* watchGetActivePublishEngagementReward() {
  yield takeLatest(getActivePublishEngagementRewardSagaAction, getActivePublishEngagementReward);
}

function* watchRemovePublishEngagementReward() {
  yield takeLatest(removePublishEngagementRewardSagaAction, removePublishEngagementReward);
}

function* watchSavePublishEngagementReward() {
  yield takeLatest(savePublishEngagementRewardSagaAction, savePublishEngagementReward);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetEngagementReward),
    fork(watchSaveEngagementReward),
    fork(watchPublishEngagementReward),

    fork(watchGetPublishEngagementReward),
    fork(watchGetActivePublishEngagementReward),
    fork(watchSavePublishEngagementReward),
    fork(watchRemovePublishEngagementReward),

    fork(watchGetEngagementRewardVariable),
  ]);
}
