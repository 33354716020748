import { handleActions } from 'redux-actions';

import {
  getLocalOffersSucceededReduxAction,
  saveLocalOfferTabReduxAction,
} from './AdvertisementAction';

// reducers
const defaultState = {
  localOffers: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  activeLocalOfferTab: 'all',
};

const reducer = handleActions({
  [getLocalOffersSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      localOffers: payload,
    };
  },
  [saveLocalOfferTabReduxAction](state, { payload }) {
    return {
      ...state,
      activeLocalOfferTab: payload,
    };
  },
}, defaultState);

export default reducer;
