import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button } from 'reactstrap';

import LocaleService from '@services/LocaleService';

class DisplayOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayOptionsIsOpen: false,
    };
  }

  toggleDisplayOptions = () => {
    this.setState((prevState) => ({
      displayOptionsIsOpen: !prevState.displayOptionsIsOpen,
    }));
  };

  render() {
    const { children } = this.props;
    const { displayOptionsIsOpen } = this.state;
    const i18n = LocaleService.getTranslations('general');

    return (
      <>
        <Button
          color="empty"
          className="pt-0 pl-0 d-inline-block d-md-none"
          onClick={this.toggleDisplayOptions}
          title={i18n.table.display_options.title}
        >
          {i18n.table.display_options.label}
          <i className="simple-icon-arrow-down align-middle" />
        </Button>
        <Collapse
          isOpen={displayOptionsIsOpen}
          className="d-md-block"
          id="displayOptions"
        >
          <div className="d-block d-md-inline-block pt-1">
            {children}
          </div>
        </Collapse>
      </>
    );
  }
}

DisplayOption.propTypes = {
  children: PropTypes.any.isRequired,
};

export default DisplayOption;
