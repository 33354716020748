import {
  all, fork, takeLatest, takeEvery,
} from 'redux-saga/effects';

import {
  getUsersSagaAction,
  banUserSagaAction,
  getBannedUsersSagaAction,
  getAdminUsersSagaAction,
  createAdminUserSagaAction,
  updateAdminUserSagaAction,
  deleteAdminUserSagaAction,
  getMerchantsSagaAction,
  saveTagsSagaAction,
  getTagsSagaAction,
  searchUserSagaAction,
  assignUserTagsSagaAction,
  deleteUserTagsSagaAction,
} from './UserAction';
import {
  getUsers,
  getBannedUsers,
  banUser,
  getAdminUsers,
  createAdminUser,
  updateAdminUser,
  deleteAdminUser,
  getMerchants,
  createTags,
  getAllUserTags,
  searchUser,
  assignUserTags,
  deleteUserTag,
} from './UserWorker';

function* watchGetUsers() {
  yield takeLatest(getUsersSagaAction, getUsers);
}

function* watchGetBannedUsers() {
  yield takeLatest(getBannedUsersSagaAction, getBannedUsers);
}

function* watchGetAdminUsers() {
  yield takeLatest(getAdminUsersSagaAction, getAdminUsers);
}

function* watchBanUser() {
  yield takeEvery(banUserSagaAction, banUser);
}

function* watchCreateAdminUser() {
  yield takeLatest(createAdminUserSagaAction, createAdminUser);
}

function* watchUpdateAdminUser() {
  yield takeLatest(updateAdminUserSagaAction, updateAdminUser);
}

function* watchDeleteAdminUser() {
  yield takeLatest(deleteAdminUserSagaAction, deleteAdminUser);
}

function* watchGetMerchants() {
  yield takeLatest(getMerchantsSagaAction, getMerchants);
}

function* watchCreateTags() {
  yield takeLatest(saveTagsSagaAction, createTags);
}

function* watchGetUserTags() {
  yield takeLatest(getTagsSagaAction, getAllUserTags);
}

function* watchSearchUser() {
  yield takeLatest(searchUserSagaAction, searchUser);
}

function* watchAssignUserTag() {
  yield takeLatest(assignUserTagsSagaAction, assignUserTags);
}

function* watchDeleteUserTag() {
  yield takeLatest(deleteUserTagsSagaAction, deleteUserTag);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchGetBannedUsers),
    fork(watchGetAdminUsers),
    fork(watchBanUser),
    fork(watchCreateAdminUser),
    fork(watchUpdateAdminUser),
    fork(watchDeleteAdminUser),
    fork(watchGetMerchants),
    fork(watchCreateTags),
    fork(watchGetUserTags),
    fork(watchSearchUser),
    fork(watchAssignUserTag),
    fork(watchDeleteUserTag),
  ]);
}
