import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LocaleService from '@services/LocaleService';
import './style.scss';

class Search extends Component {
  searchTimeout;

  handleInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;

    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      const { onSearchChange } = this.props;
      onSearchChange(value);
    }, 500);
  }

  render() {
    const i18n = LocaleService.getTranslations('general');

    return (
      <div className="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
        <input
          type="text"
          name="keyword"
          id="search"
          placeholder={i18n.table.placeholder.search}
          onChange={this.handleInputChange}
        />
      </div>
    );
  }
}

Search.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

export default Search;
