import { all, fork, takeEvery } from 'redux-saga/effects';

import {
  setContainerClassnamesSagaAction,
  addContainerClassnameSagaAction,
  clickMobileMenuSagaAction,
  // incrementAction,
} from './MenuAction';
import { setContainerClassnames, addContainerClassname, clickMobileMenu } from './MenuWorker';

/**
 * Watcher Declaration
 * Suggested Watcher Name Standard:
 *
 *    actionName with the prefix "watch" and without the suffix "Action"
 *
 * Example:
 * function* watchIncrement() {
 *    yield takeEvery(incrementAction, increment);
 * }
 * */

function* watchSetContainerClassnames() {
  yield takeEvery(setContainerClassnamesSagaAction, setContainerClassnames);
}

function* watchAddContainerClassname() {
  yield takeEvery(addContainerClassnameSagaAction, addContainerClassname);
}

function* watchClickMobileMenu() {
  yield takeEvery(clickMobileMenuSagaAction, clickMobileMenu);
}

export default function* rootSaga() {
  yield all([
    // fork(watchIncrement),
    fork(watchSetContainerClassnames),
    fork(watchAddContainerClassname),
    fork(watchClickMobileMenu),
  ]);
}
