/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getLocalOffersSagaAction = createAction('APZ_ADVERTISEMENT_GET_LOCAL_OFFERS');
export const getLocalOffersSucceededReduxAction = createAction('APZ_ADVERTISEMENT_GET_LOCAL_OFFERS_SUCCEEDED_REDUX');

export const removeLocalOffersSagaAction = createAction('APZ_ADVERTISEMENT_REMOVE_LOCAL_OFFERS');
export const removeLocalOffersSucceededReduxAction = createAction('APZ_ADVERTISEMENT_REMOVE_LOCAL_OFFERS_SUCCEEDED_REDUX');

export const restoreLocalOffersSagaAction = createAction('APZ_ADVERTISEMENT_RESTORE_LOCAL_OFFERS');
export const restoreLocalOffersSucceededReduxAction = createAction('APZ_ADVERTISEMENT_RESTORE_LOCAL_OFFERS_SUCCEEDED_REDUX');

export const deleteLocalOffersSagaAction = createAction('APZ_ADVERTISEMENT_DELETE_LOCAL_OFFERS');
export const deleteLocalOffersSucceededReduxAction = createAction('APZ_ADVERTISEMENT_DELETE_LOCAL_OFFERS_SUCCEEDED_REDUX');

export const saveLocalOfferTabReduxAction = createAction('APZ_ADVERTISEMENT_SAVE_LOCAL_OFFER_TAB_REDUX');
