/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getCommentReportsSagaAction = createAction('APZ_REPORT_GET_COMMENT_REPORTS');
export const getCommentReportsSucceededReduxAction = createAction('APZ_REPORT_GET_COMMENT_REPORTS_SUCCEEDED_REDUX');

export const getPostReportsSagaAction = createAction('APZ_REPORT_GET_POST_REPORTS');
export const getPostReportsSucceededReduxAction = createAction('APZ_REPORT_GET_POST_REPORTS_SUCCEEDED_REDUX');

export const getUserReportsSagaAction = createAction('APZ_REPORT_GET_USER_REPORTS');
export const getUserReportsSucceededReduxAction = createAction('APZ_REPORT_GET_USER_REPORTS_SUCCEEDED_REDUX');

export const removeReportedItemSagaAction = createAction('APZ_REPORT_REMOVE_REPORTED_ITEM');
export const restoreReportedItemSagaAction = createAction('APZ_REPORT_RESTORE_REPORTED_ITEM');
export const ignoreReportedItemSagaAction = createAction('APZ_REPORT_IGNORE_REPORTED_ITEM');

export const getItemReportsSagaAction = createAction('APZ_REPORT_GET_ITEM_REPORTS');

export const savePostTabReduxAction = createAction('APZ_REPORT_SAVE_POST_TAB_REDUX');
export const saveCommentTabReduxAction = createAction('APZ_REPORT_SAVE_COMMENT_TAB_REDUX');
export const saveUserTabReduxAction = createAction('APZ_REPORT_SAVE_USER_TAB_REDUX');
