import React from 'react';
import ReactDOM from 'react-dom';
import App from '@app/App';

import constantsConfig from '@config/constants';
import * as serviceWorker from './serviceWorker';

// import { runScript } from './script-inject';
// runScript();

import './index.scss';

const {
  isMultiColorActive, defaultColor, themeColorStorageKey, isDarkSwitchActive,
} = constantsConfig;

const color = (isMultiColorActive || isDarkSwitchActive)
  && localStorage.getItem(themeColorStorageKey)
  ? localStorage.getItem(themeColorStorageKey)
  : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    ReactDOM.render(<App />, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
};
render();
