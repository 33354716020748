import { handleActions } from 'redux-actions';

import {

} from './SystemSettingsAction';

// reducers
const defaultState = {

};

const reducer = handleActions({
  // getHomeAction = Variable| state, action(payload)
}, defaultState);

export default reducer;
