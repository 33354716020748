import { handleActions } from 'redux-actions';

import {
  createPostSagaAction,
  createPostFailedReduxAction,
  editPostSagaAction,
  editPostFailedReduxAction,
} from './FeedAction';

// reducers
const defaultState = {
  errors: {},
};

const reducer = handleActions({
  [createPostSagaAction](state) {
    return { ...state, errors: {} };
  },
  [createPostFailedReduxAction](state, { payload }) {
    return { ...state, errors: payload };
  },
  [editPostSagaAction](state) {
    return { ...state, errors: {} };
  },
  [editPostFailedReduxAction](state, { payload }) {
    return { ...state, errors: payload };
  },
}, defaultState);

export default reducer;
