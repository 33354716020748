import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  getSuggestedPlacesSagaAction,
  getLocationDetailsSagaAction,
  getPlaceIdDetailsSagaAction,
} from './LocationAction';
import {
  getSuggestedLocations,
  getLocationDetails,
  getPlaceIdDetails,
} from './LocationWorker';

function* watchGetSuggestedLocations() {
  yield takeLatest(getSuggestedPlacesSagaAction, getSuggestedLocations);
}

function* watchGetLocationDetails() {
  yield takeLatest(getLocationDetailsSagaAction, getLocationDetails);
}

function* watchGetPlaceIdDetails() {
  yield takeLatest(getPlaceIdDetailsSagaAction, getPlaceIdDetails);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSuggestedLocations),
    fork(watchGetPlaceIdDetails),
    fork(watchGetLocationDetails),
  ]);
}
