import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers/main';
import sagas from './sagas';

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: [ 'Auth', 'User', 'Report', 'Advertisement' ],
  },
  reducers,
);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [ sagaMiddleware ];

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares)),
);

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

if (module.hot) {
  module.hot.accept('./reducers/main', () => {
    // eslint-disable-next-line global-require
    const nextRootReducer = require('./reducers/main');
    store.replaceReducer(nextRootReducer);
  });
}

export { store, persistor };
