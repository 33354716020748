import { handleActions } from 'redux-actions';

import {
  getEngagementRewardVariableSuccessReduxAction,
  savePublishEngagementRewardSuccessAction,

  getPublishEngagementRewardSuccessAction,
} from './EngagementRewardAction';

// reducers
const defaultState = {
  variables: {},
  published: {
    meta: {
      last_page: 1,
    },
  },
};

const reducer = handleActions({
  // getHomeAction = Variable| state, action(payload)
  [getEngagementRewardVariableSuccessReduxAction](state, { payload }) {
    return {
      ...state,
      variables: { ...payload },
    };
  },
  [getPublishEngagementRewardSuccessAction](state, { payload }) {
    return {
      ...state,
      published: { ...payload },
    };
  },
  [savePublishEngagementRewardSuccessAction](state, { payload }) {
    const { published } = state;
    const data = [ payload, ...published.data ];

    return {
      ...state,
      published: {
        ...published,
        data,
      },
    };
  },
}, defaultState);

export default reducer;
