import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { LoadingBlock } from '@shared/utils';
import { store, persistor } from './store';

const LazyAppRouter = lazy(() => import('@app/AppRouter'));

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<LoadingBlock />}>
        <LazyAppRouter />
      </Suspense>
    </PersistGate>
  </Provider>
);

export default App;
