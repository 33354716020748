/* eslint-disable import/prefer-default-export */
import { put, call } from 'redux-saga/effects';

import {
  getRequest, postRequest,
} from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import {
  getCommentReportsSucceededReduxAction,
  getPostReportsSucceededReduxAction,
  getUserReportsSucceededReduxAction,
} from './ReportAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getCommentReports({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(getRequest, 'admin/reports/comments', { queryParams: payload.data });
    const response = yield request.$promise;

    if (onSuccess) {
      onSuccess(response.data);
    }

    yield put(getCommentReportsSucceededReduxAction(response.data));
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getPostReports({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(getRequest, 'admin/reports/posts', { queryParams: payload.data });
    const response = yield request.$promise;

    if (onSuccess) {
      onSuccess(response.data);
    }

    yield put(getPostReportsSucceededReduxAction(response.data));
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getUserReports({ payload }) {
  const { onSuccess, onError } = payload;
  try {
    const request = yield call(getRequest, 'admin/reports/users', { queryParams: payload.data });
    const response = yield request.$promise;

    if (onSuccess) {
      onSuccess(response.data);
    }

    yield put(getUserReportsSucceededReduxAction(response.data));
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* removeReportedItem({ payload }) {
  try {
    const request = yield call(postRequest, 'admin/reports/remove', payload.data);
    yield request.$promise;

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    handleError(error);
  }
}

export function* restoreReportedItem({ payload }) {
  try {
    const request = yield call(postRequest, 'admin/reports/restore', payload.data);
    yield request.$promise;

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    handleError(error);
  }
}

export function* ignoreReportedItem({ payload }) {
  try {
    const request = yield call(postRequest, 'admin/reports/ignore', payload.data);
    yield request.$promise;

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    handleError(error);
  }
}

export function* getItemReports({ payload }) {
  try {
    const request = yield call(getRequest, `admin/reports/${payload.id}/details`, { queryParams: payload.data });
    const response = yield request.$promise;

    if (payload.onSuccess) {
      payload.onSuccess(response.data);
    }
  } catch (error) {
    if (payload.onError) {
      payload.onError();
    }
    handleError(error);
  }
}
