import { handleActions } from 'redux-actions';

import {
  getCommentReportsSucceededReduxAction,
  getPostReportsSucceededReduxAction,
  getUserReportsSucceededReduxAction,
  savePostTabReduxAction,
  saveCommentTabReduxAction,
  saveUserTabReduxAction,
} from './ReportAction';

// reducers
const defaultState = {
  comments: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  posts: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  users: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  errors: {},
  activePostTab: 'open',
  activeCommentTab: 'open',
  activeUserTab: 'open',
};

const reducer = handleActions({
  [getCommentReportsSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      comments: payload,
    };
  },
  [getPostReportsSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      posts: payload,
    };
  },
  [getUserReportsSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      users: payload,
    };
  },
  [savePostTabReduxAction](state, { payload }) {
    return {
      ...state,
      activePostTab: payload,
    };
  },
  [saveCommentTabReduxAction](state, { payload }) {
    return {
      ...state,
      activeCommentTab: payload,
    };
  },
  [saveUserTabReduxAction](state, { payload }) {
    return {
      ...state,
      activeUserTab: payload,
    };
  },
}, defaultState);

export default reducer;
