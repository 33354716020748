import {
  put,
  // call,
} from 'redux-saga/effects';

import {
  setContainerClassnamesReduxAction,
  addContainerClassnameReduxAction,
  clickMobileMenuReduxAction,
  // incrementSucceededReduxAction, incrementFailedReduxAction,
} from './MenuAction';

/**
 * Async Request
 * Suggested Async Request Name Standard:
 *
 *    actionName with the suffix "Async"
 *
 * Example:
 * const incrementAsync = async () => {
 *   const promise = new Promise((resolve, reject) => {
 *     setTimeout(() => {
 *       resolve({ error: false });
 *     }, 3000);
 *     reject(new Error({ error: true }));
 *   });
 *   await promise;
 * };
 * */

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use call for async request
 *      yield call(incrementAsync);
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* setContainerClassnames({ payload }) {
  const { strCurrentClasses, selectedMenuHasSubItems } = payload;
  let { clickIndex } = payload;

  const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter((x) => x !== '') : '';
  let nextClasses = '';

  if (!selectedMenuHasSubItems) {
    if (currentClasses.includes('menu-default') && (clickIndex % 4 === 0 || clickIndex % 4 === 3)) {
      clickIndex = 1;
    }
    if (currentClasses.includes('menu-sub-hidden') && (clickIndex % 4 === 2)) {
      clickIndex = 0;
    }
    if (currentClasses.includes('menu-hidden') && (clickIndex % 4 === 2 || clickIndex % 4 === 3)) {
      clickIndex = 0;
    }
  }

  if (clickIndex % 4 === 0) {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden';
    }
    clickIndex = 0;
  } else if (clickIndex % 4 === 1) {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  } else if (clickIndex % 4 === 2) {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
    }
  } else if (clickIndex % 4 === 3) {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  }
  if (currentClasses.includes('menu-mobile')) {
    nextClasses += ' menu-mobile';
  }

  yield put(setContainerClassnamesReduxAction({
    containerClassnames: nextClasses,
    menuClickCount: clickIndex,
  }));
}

export function* addContainerClassname({ payload }) {
  const { classname, strCurrentClasses } = payload;
  const newClasses = !strCurrentClasses.indexOf(classname) > -1 ? `${strCurrentClasses} ${classname}` : strCurrentClasses;

  yield put(addContainerClassnameReduxAction(newClasses));
}

export function* clickMobileMenu({ payload }) {
  const { strCurrentClasses } = payload;

  const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter((x) => x !== '' && x !== 'sub-show-temporary') : '';
  let nextClasses = '';

  if (currentClasses.includes('main-show-temporary')) {
    nextClasses = (currentClasses.filter((x) => x !== 'main-show-temporary')).join(' ');
  } else {
    nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
  }

  yield put(clickMobileMenuReduxAction({
    containerClassnames: nextClasses,
    menuClickCount: 0,
  }));
}
