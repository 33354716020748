/* eslint-disable import/prefer-default-export */
import { put } from 'redux-saga/effects';

import { getRequest } from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import { toggleRefreshReduxAction } from '@reducers/loading/LoadingAction';
import {
  getDashboardInfoSucceededReduxAction,
} from './DashboardAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getDashboardInfo() {
  put(toggleRefreshReduxAction());
  try {
    const response = yield getRequest('admin/dashboard').$promise;
    yield put(getDashboardInfoSucceededReduxAction(response.data));
    // put(toggleRefreshReduxAction());
  } catch (error) {
    // put(toggleRefreshReduxAction());
    handleError(error);

    throw error;
  }
}
