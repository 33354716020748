import React from 'react';
import PropTypes from 'prop-types';

const ErrorFeedback = ({
  serverErrors,
  formError,
  touched,
}) => {
  // form error should only be shown if touched
  // server error should be shown if there's on it
  if (serverErrors.length || (formError && touched)) {
    const errorData = serverErrors.length ? serverErrors[0] : formError;
    return (
      <div className="invalid-feedback d-block">
        {errorData}
      </div>
    );
  }

  // else
  return null;
};

ErrorFeedback.defaultProps = {
  serverErrors: [],
  formError: null,
  touched: false,
};

ErrorFeedback.propTypes = {
  serverErrors: PropTypes.array,
  formError: PropTypes.string,
  touched: PropTypes.bool,
};

export default ErrorFeedback;
