import { handleActions } from 'redux-actions';

import {
  getDashboardInfoSucceededReduxAction,
} from './DashboardAction';

// reducers
const defaultState = {
  total_users: 0,
  total_merchants: 0,
  total_global_profiles: 0,
  total_offers: 0,
  total_post_reports: 0,
  total_user_reports: 0,
};

const reducer = handleActions({
  [getDashboardInfoSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      ...payload,
    };
  },
}, defaultState);

export default reducer;
