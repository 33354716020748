import { all } from 'redux-saga/effects';
import authSagas from '@reducers/auth/AuthSaga';
import menuSagas from '@reducers/menu/MenuSaga';
import settingsSagas from '@reducers/settings/SettingsSaga';
import dashboardSagas from '@reducers/dashboard/DashboardSaga';
import userSagas from '@reducers/user/UserSaga';
import reportSagas from '@reducers/report/ReportSaga';
import systemSettingsSagas from '@reducers/system-settings/SystemSettingsSaga';
import feedSagas from '@reducers/feed/FeedSaga';
import locationSagas from '@reducers/location/LocationSaga';
import advertisementSaga from '@reducers/advertisement/AdvertisementSaga';
import globalProfileSaga from '@reducers/global-profile/GlobalProfileSaga';
import engagementRewardSaga from '@reducers/engagement-reward/EngagementRewardSaga';
import profileTierSaga from '@reducers/profile-tier/ProfileTierSaga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    menuSagas(),
    settingsSagas(),
    dashboardSagas(),
    userSagas(),
    reportSagas(),
    systemSettingsSagas(),
    feedSagas(),
    locationSagas(),
    advertisementSaga(),
    engagementRewardSaga(),
    globalProfileSaga(),
    profileTierSaga(),
  ]);
}
