/* eslint-disable import/prefer-default-export */
import { call } from 'redux-saga/effects';

import {
  getRequest, putRequest,
} from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import {
} from './SystemSettingsAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getSystemSettings({ payload }) {
  try {
    const request = yield call(getRequest, 'admin/system-settings', { queryParams: payload.data });
    const response = yield request.$promise;

    if (payload.onSuccess) {
      payload.onSuccess(response.data);
    }
  } catch (error) {
    if (payload.onError) {
      payload.onError();
    }
    handleError(error);
  }
}

export function* updateSystemSettings({ payload }) {
  try {
    const request = yield call(putRequest, 'admin/system-settings', payload.data);
    yield request.$promise;

    if (payload.onSuccess) {
      payload.onSuccess();
    }
  } catch (error) {
    if (payload.onError) {
      payload.onError();
    }
    handleError(error);
  }
}
