import { handleActions } from 'redux-actions';

import {
  getGlobalProfilesSucceededReduxAction,
  saveGlobalProfileTabReduxAction,
  globalProfileChangeTypeSucceededReduxAction,
} from './GlobalProfileAction';

// reducers
const defaultState = {
  globalProfiles: {
    data: [],
    meta: {
      last_page: 1,
    },
  },
  activeGlobalProfileTab: 'all',
};

const reducer = handleActions({
  [getGlobalProfilesSucceededReduxAction](state, { payload }) {
    return {
      ...state,
      globalProfiles: payload,
    };
  },
  [saveGlobalProfileTabReduxAction](state, { payload }) {
    return {
      ...state,
      activeGlobalProfileTab: payload,
    };
  },
  [globalProfileChangeTypeSucceededReduxAction](state, { payload }) {
    const { id, type } = payload;
    return {
      ...state,
      globalProfiles: {
        ...state.globalProfiles,
        data: state.globalProfiles.data.map((profile) => ({
          ...profile,
          type: profile.id === id ? type : profile.type,
        })),
      },
    };
  },
}, defaultState);

export default reducer;
