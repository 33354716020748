/* eslint-disable import/prefer-default-export */
import { put, call } from 'redux-saga/effects';

import {
  getRequest, postRequest,
} from '@services/RequestService';
import { handleError } from '@services/ErrorHandler';

import {
  getGlobalProfilesSucceededReduxAction,
  banGlobalProfileSucceededReduxAction,
  unbanGlobalProfileSucceededReduxAction,
  showGlobalProfileSucceededReduxAction,
  globalProfileChangeTypeSucceededReduxAction,
} from './GlobalProfileAction';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getGlobalProfiles({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    const request = yield call(getRequest, 'admin/global-profiles', { queryParams: data });
    const response = yield request.$promise;
    yield put(getGlobalProfilesSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* banGlobalProfile({ payload }) {
  const { id, onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, `admin/global-profiles/ban/${id}`);
    const response = yield request.$promise;
    yield put(banGlobalProfileSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* unbanGlobalProfile({ payload }) {
  const { id, onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, `admin/global-profiles/unban/${id}`);
    const response = yield request.$promise;
    yield put(unbanGlobalProfileSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* showGlobalProfile({ payload }) {
  const { id, onSuccess, onError } = payload;
  try {
    const request = yield call(getRequest, `admin/global-profiles/${id}`);
    const response = yield request.$promise;
    yield put(showGlobalProfileSucceededReduxAction(response.data));

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* globalProfileChangeType({ payload }) {
  const { data, onSuccess, onError } = payload;
  try {
    const request = yield call(postRequest, `admin/global-profiles/change-type/${data.id}`, { type: data.type });
    const response = yield request.$promise;
    yield put(globalProfileChangeTypeSucceededReduxAction(data));

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}
