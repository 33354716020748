/* eslint-disable import/prefer-default-export */
import { call } from 'redux-saga/effects';

import {
  // getLocationIQApiRequest,
  getGoogleApiRequest,
} from '@services/RequestService';
import {
  getSuggestedPlacesRequest,
  getLocationRequest,
  getPlaceDetailRequest,
} from '@services/google/GoogleApiService';
import { handleError } from '@services/ErrorHandler';

import app from '@config/app';

/**
 * Worker Declaration
 * Suggested Worker Name Standard:
 *
 *    actionName without the suffix "Action"
 *
 * Example:
 * export function* increment({ payload }) {
 *    try {
 *      // Use async request from request service
 *      yield getRequest('path/to/url').$promise;
 *      // Use put to update the reducer based from the action
 *      yield put(incrementSucceededAction(payload));
 *    } catch (error) {
 *      // Use put to update the reducer based from the action
 *      yield put(incrementFailedAction());
 *    }
 *  }
 * */

export function* getSuggestedLocations({ payload }) {
  const { data, onSuccess, onError } = payload;
  const { data: params, url, transformer } = getSuggestedPlacesRequest(data);
  try {
    const request = yield call(getGoogleApiRequest, url, params);
    const response = yield request.$promise;
    const responseData = transformer(response.data);

    if (onSuccess) {
      onSuccess(responseData);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getPlaceIdDetails({ payload }) {
  const { data, onSuccess, onError } = payload;
  const { data: params, url, transformer } = getPlaceDetailRequest(data);
  try {
    const request = yield call(getGoogleApiRequest, url, params);
    const response = yield request.$promise;
    const responseData = transformer(response.data);

    if (onSuccess) {
      onSuccess(responseData);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}

export function* getLocationDetails({ payload }) {
  const { data, onSuccess, onError } = payload;
  const { data: params, url = 'maps/api/geocode/json', transformer } = getLocationRequest(data);

  try {
    const request = yield call(getGoogleApiRequest, url, {
      queryParams: params,
      key: app.google.apiKey,
    });
    const response = yield request.$promise;
    const responseData = transformer(response.data);
    // const venue = results[0] || result;
    // const successData = {
    //   id: venue.place_id,
    //   location: {
    //     lat: parseFloat(params.latitude),
    //     lng: parseFloat(params.longitude),
    //     shortAddress: venue.name || venue.formatted_address,
    //     description: venue.name || venue.formatted_address,
    //   },
    // };

    if (onSuccess) {
      onSuccess(responseData);
    }
  } catch (error) {
    handleError(error);

    if (onError) {
      onError(error);
    }
  }
}
