import {
  all, fork, takeLatest,
} from 'redux-saga/effects';

import {
  loginSagaAction,
  logoutSagaAction,
  updateMeSagaAction,
} from './AuthAction';
import {
  login,
  logout,
  updateMe,
} from './AuthWorker';

function* watchLogin() {
  yield takeLatest(loginSagaAction, login);
}

function* watchLogout() {
  yield takeLatest(logoutSagaAction, logout);
}

function* watchUpdateMe() {
  yield takeLatest(updateMeSagaAction, updateMe);
}

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchUpdateMe),
  ]);
}
