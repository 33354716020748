/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody } from 'reactstrap';

const IconCard = ({
  className, icon, title, value,
  onClick,
}) => (
  <div className={`icon-row-item ${className}`} onClick={onClick}>
    <Card>
      <CardBody className="text-center">
        <i className={icon} />
        <p className="card-text font-weight-semibold mb-0">
          {title}
        </p>
        <p className="lead text-center">{value}</p>
      </CardBody>
    </Card>
  </div>
);

IconCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};

IconCard.defaultProps = {
  className: 'mb-4',
};

export default IconCard;
