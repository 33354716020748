/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const getPostsSagaAction = createAction('APZ_ANNOUNCEMENT_GET_POST');

export const createPostSagaAction = createAction('APZ_ANNOUNCEMENT_CREATE_POST');
export const createPostFailedReduxAction = createAction('APZ_ANNOUNCEMENT_CREATE_POST_FAILED');

export const editPostSagaAction = createAction('APZ_ANNOUNCEMENT_EDIT_POST');
export const editPostFailedReduxAction = createAction('APZ_ANNOUNCEMENT_EDIT_POST_FAILED');

export const deletePostSagaAction = createAction('APZ_USER_DELETE_POST');

export const getGlobalProfilesSagaAction = createAction('APZ_ANNOUNCEMENT_GET_GLOBAL_PROFILES');
